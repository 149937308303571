<template>
  <div>
    <b-card>
      <b-row class="align-items-center">
        <b-col
          lg="5"
          class="mb-2"
        >
          <b-form-group>
            <h5>Account id</h5>
            <b-form-input
              v-model="accountId"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col
          lg="2"
          class="mb-2"
        >
          <b-button
            :disabled="loading"
            @click.prevent="searchTrades"
          >
            Search
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <b-card
        v-if="account"
      >
        <b-row>
          <b-col>
            <h4>
              {{ account.account_id }} - {{ account.user.full_name }} / {{ account.user.email }}
            </h4>
          </b-col>
          <b-col>
            <b-button
              variant="outline-primary"
              class="btn-icon float-right mb-auto mr-2"
              @click="hideSensitiveInformation = !hideSensitiveInformation"
            >
              <feather-icon :icon="!hideSensitiveInformation ? 'EyeOffIcon' : 'EyeIcon'" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        v-if="Object.keys(trades).length > 0"
      >
        <trade-table
          v-for="(accounts, key) in trades"
          :key="key"
          :name="`${accounts[0].type === 1 ? 'BUY' : 'SELL'} ${accounts[0].symbol} / open time - ${accounts[0].open_datetime} / open price - ${accounts[0].open_price}`"
          :accounts="accounts"
          :fields="fields"
          :show-linkage-btn="false"
        >
          <template #cell(open_datetime)="data">
            {{ formatDateTime(data.item.open_datetime) }}
          </template>

          <template #cell(close_datetime)="data">
            {{ formatDateTime(data.item.close_datetime) }}
          </template>

          <template #cell(type)="data">
            <span v-if="data.item.type === 1">BUY</span>
            <span v-else>SELL</span>
          </template>

          <template #cell(open_price)="data">
            {{ data.item.open_price }}
          </template>

          <template #cell(close_price)="data">
            {{ data.item.close_price }}
          </template>

          <template #cell(email)="data">
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user_id } }">
              {{ hideSensitiveInformation && account.user.email !== data.item.email ? '***********' : data.item.email }}
            </b-link>
          </template>

          <template #cell(login)="data">
            <b-link :to="{ name: 'account-detail', params: { id: data.item.login } }">
              {{ hideSensitiveInformation && account.account_id !== data.item.login ? `${data.item.login.toString().slice(0,3)}********` : data.item.login }}
            </b-link>
          </template>
        </trade-table>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BOverlay, BLink,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TradeTable from '@/views/trading-history/components/TradeTable.vue'
import TradingHistoryAPI from '@/api/trading-history'
import { formatDateTime } from '@/plugins/formaters'

const fields = [
  {
    key: 'open_datetime',
    sortable: true,
  },
  {
    key: 'close_datetime',
    sortable: true,
  },
  {
    key: 'type',
    sortable: true,
  },
  {
    key: 'open_price',
    sortable: true,
  }, {
    key: 'close_price',
    sortable: true,
  },
  {
    key: 'profit',
    sortable: true,
  },
  {
    key: 'email',
    sortable: true,
  },
  {
    key: 'login',
    sortable: true,
  },
  {
    key: 'account_type_name',
    sortable: true,
  },
  {
    key: 'account_subtype_name',
    sortable: true,
  },
]

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    BLink,
    TradeTable,
  },
  data() {
    return {
      accountId: null,
      loading: false,
      trades: [],
      account: null,
      hideSensitiveInformation: false,
      fields,
    }
  },
  methods: {
    formatDateTime,
    async searchTrades() {
      if (!this.accountId) {
        return
      }
      this.loading = true
      TradingHistoryAPI.getIndividualAnalysis(this.accountId)
        .then(data => {
          this.trades = data.trades
          this.account = data.account
          this.loading = false
        })
        .catch(async error => {
          if (error.status === 524) {
            await this.sleep(90000)
            await this.searchTrades()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Bad parameters',
                icon: 'SendIcon',
                text: 'Account not found or user do not start trading yet!',
                variant: 'danger',
              },
            })
          }
        })
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
  },
}
</script>
